export default [
  {
    path: '/',
    name: "app-root",
    meta: {
      action: "read",
      resource: "AppRoot",
      isPublicRoute: true,
      layout: 'horizontal',
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
      redirectIfLoggedIn: true,
    },
    component: () => import('@/pages/public/home'),
  },
  {
    path: '/about-us',
    name: "about-us",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/about-us/history.vue'),
  },
  {
    path: '/about-us/history',
    name: "about-us-history",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/about-us/history.vue'),
  },
  {
    path: '/about-us/leadership',
    name: "about-us-leadership",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/about-us/leadership.vue'),
  },
  {
    path: '/about-us/sectors',
    name: "about-us-sectors",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/about-us/sectors.vue'),
  },
  {
    path: '/about-us/campus',
    name: "about-us-campus",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/about-us/campus.vue'),
  },
  {
    path: '/events',
    name: "events",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/events/list'),
  },
  {
    path: '/events/:id',
    name: 'single-event',
    component: () => import('@/pages/public/events/view'),
    meta: {
      action: "read",
      pageTitle: 'Event Details',
      layout: 'horizontal',
      isPublicRoute: true,
      redirectIfLoggedIn: true,
      resource: "PublicRoute",
      logPageName: "View Event Details",
      contentClass: 'm-0 pl-2 pt-5 pr-0 pt-5x',
    },
  },
  {
    path: '/cinema',
    name: "cinema",
    meta: {
      action: "read",
      isPublicRoute: true,
      layout: 'horizontal',
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/cinema'),
  },
  {
    path: '/entrepreneurs-club',
    name: "entrepreneurs-club",
    meta: {
      action: "read",
      isPublicRoute: true,
      layout: 'horizontal',
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/entrepreneurs-club'),
  },
  {
    path: '/investors-club',
    name: "investors-club",
    meta: {
      action: "read",
      isPublicRoute: true,
      layout: 'horizontal',
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/investors-club'),
  },
  {
    path: '/blog',
    name: "blog",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      resource: "PublicRoute",
      redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/blog/list'),
  },
  {
    path: '/blog/:slug',
    name: 'single-blog',
    component: () => import('@/pages/public/blog/view'),
    meta: {
      action: "read",
      pageTitle: 'Blogs',
      layout: 'horizontal',
      isPublicRoute: true,
      redirectIfLoggedIn: true,
      resource: "PublicRoute",
      logPageName: "View Blog Details",
      contentClass: 'm-0 pl-2 pt-5 pr-0 pt-5x',
    },
  },
  {
    path: '/privacy-policy',
    name: "privacy",
    meta: {
      layout: 'full',
      action: "read",
      isPublicRoute: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/privacy'),
  },
  {
    path: '/terms',
    name: "terms",
    meta: {
      layout: 'full',
      action: "read",
      isPublicRoute: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/public/terms'),
  }
]
