
import maskdata from "maskdata"
import useJwt from '@/auth/jwt/useJwt'

import { get } from "lodash";
import { initialAbility } from '@/libs/acl/config'
import { avatarText, formatDateToMonthShort } from '@core/utils/filter'
import { formatMoney, formatDate, formatDateDistance, formatTime, formatDuration } from "@/@core/utils/utils";

import { MUTATE_LOGIN_STATUS } from "@/store/config/mutation-keys"

export default {
  data() {
    return {
      avatarText
    }
  },
  computed: {
    generalAppSettings() {
      return get(this.$store, 'getters[auth/settings]', {});
    },
    currentUser() {
      return get(this.$store, 'getters[auth/userData]', {});
    },
    currentUserClientGroup() {
      return get(this.$store, 'getters[auth/clientGroup]', {});
    },
    isLoansModuleEnabled() {
      return get(this.generalAppSettings, 'loan_settings.enable_loan_module', false)
    },
    currentUserRole() {
      return get(this.currentUser, 'user_type', '');
    },
    isCurrentUserClient() {
      return this.currentUserRole === "client"
    },
    onlineUsers() {
      return get(this.$store, 'getters[auth/onlineUsers]', []);
    },
    isLoggedIn() {
      return this.$store.getters[`auth/isLoggedIn`]
    },
  },
  methods: {
    formatTime,
    formatDuration,
    generateReferralLink() {
      const { _id } = this.currentUser;
      const registrationRoute = "/auth/register"
      if (_id) {
        return `${location.origin}${registrationRoute}?referrer=${_id}`;
      }
      return "";
    },
    maskPhoneNumber(phone) {
      const options = {
        maskWith: "*",
        unmaskedStartDigits: 4,
        unmaskedEndDigits: 2,
      };
      return maskdata.maskPhone(phone, options);
    },
    maskEmail(email) {
      const options = {
        maskWith: "*",
        unmaskedStartCharactersBeforeAt: 3,
        unmaskedEndCharactersAfterAt: 3,
        maskAtTheRate: true
      };
      return maskdata.maskEmail2(email, options);
    },
    formatDateToMonthShort,
    playSound(filename) {
      const mp3Source = '<source src="/' + filename + '.mp3" type="audio/mpeg">';
      const oggSource = '<source src="/' + filename + '.ogg" type="audio/ogg">';
      const embedSource = '<embed hidden="true" autostart="true" loop="false" src="/' + filename + '.mp3">';
      document.getElementById("sound-source").innerHTML = '<audio autoplay="autoplay">' + mp3Source + oggSource + embedSource + '</audio>';
    },
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    getActualValueFromSource(source, path, defaultValue = "") {
      const value = get(source, path, defaultValue);
      if (value) return value;
      return defaultValue;
    },
    generateRandomID(length = 6) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    formatMoney(money, fraction = 2) {
      if (!money) {
        return 0.00
      }
      return formatMoney(money, fraction);
    },
    formatDate(date, formatStr = "dd-MM-yyyy") {
      if (!date) {
        return ""
      }
      return formatDate(date, formatStr);
    },
    capitalizeFirstWord(text) {
      if (!text) {
        return text;
      }
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    sentenceCase(text) {
      if (!text) {
        return text;
      }
      let sentenceCase = "";
      const splitText = text.split(" ");
      splitText.forEach(txt => {
        sentenceCase += txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase() + ' ';
      });
      return sentenceCase
    },
    formatDateDistance(date) {
      if (!date) {
        return ""
      }
      return formatDateDistance(date)
    },
    getValidationState({ dirty, validated, required: fieldRequired, changed, valid = null }) {
      const result = dirty || validated ? valid : null
      return !fieldRequired && !changed ? null : result
    },
    resolveStatusVariant(status) {
      const secondaryVariants = [
        'draft'
      ];

      const warningVariants = [
        'pending',
        'flagged',
        'admin-approve',
        'pending-product-delivery',
        'inactive',
        "pending_confirmation",
        "pending_email",
        "pending_sms",
        "pending_docs_verification",
        'medium',
        "submitted"
      ];

      const successVariants = [
        'approved',
        'paid',
        'active',
        'open',
        'delivered',
        'confirmed',
        'low',
        'success',
        'completed'
      ];

      const infoVariants = [
        'archive'
      ];

      const dangerVariants = [
        'deleted',
        'cancelled',
        'rejected',
        'declined',
        'not_fulfilled',
        'banned',
        'high',
        'closed',
        'expired',
        'overdue',
        'loss',
        'error',
        'danger',
        'failed'
      ];

      if (secondaryVariants.includes(status)) return 'secondary';
      if (warningVariants.includes(status)) return 'warning';
      if (successVariants.includes(status)) return 'success';
      if (infoVariants.includes(status)) return 'info';
      if (dangerVariants.includes(status)) return 'danger';

      return 'primary';
    },
    resolveUserRoleVariant(role) {
      if (role === 'client') return 'primary'
      if (role === 'vendor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },
    resolveUserRoleIcon(role) {
      if (role === 'client') return 'UserIcon'
      if (role === 'vendor') return 'ShoppingCartIcon'
      if (role === 'admin') return 'ServerIcon'
      return 'UserIcon'
    },
    getLoanStatusTextForClient(status) {
      if (status === 'flagged' || status === 'admin-approve' || status === 'pending-product-delivery') {
        return 'pending'
      }
      return status
    },
    getLoanStatusTextForAdmin(status) {
      if (status === 'admin-approve') {
        return 'pass'
      }
      if (status === 'pending-product-delivery') {
        return 'Pending Product Delivery'
      }
      return status
    },
    getUserStatusText(status) {
      if (status === "pending_confirmation") {
        return "Pending"
      }
      return status
    },
    getProductRequestStatusText(status) {
      if (status === "not_fulfilled") {
        return "Not Fulfilled"
      }

      if (this.isCurrentUserClient && ['flagged', 'admin-approve'].includes(status)) {
        return 'Pending'
      }

      if (this.isCurrentUserAdmin && status === 'admin-approve') {
        return 'Pass'
      }

      return status
    },
    extractTextContentFromHTML(s) {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getProductRating(productRatings = []) {
      return productRatings.reduce((a, rating) => a + rating.rate, 0)
    },
    getTransactionTypeText(transaction_type) {
      if (transaction_type === 'loan_repayment') {
        return 'Loan Repayment'
      }

      if (transaction_type === 'loan_approved') {
        return 'Loan Approved'
      }

      if (transaction_type === 'loan_payout') {
        return 'Loan Payout'
      }

      if (transaction_type === 'loan_disbursed') {
        return 'Loan Disbursed'
      }

      if (transaction_type === 'product_delivered') {
        return 'Product Delivered'
      }

      if (transaction_type === 'product_approved') {
        return 'Product Request Approved'
      }


      return "-";
    },
    useJwt() {
      return useJwt
    },
    displaySweetError(error_message, title = "Error!") {
      this.$swal({
        title,
        text: error_message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    isUserOnline(user_id) {
      const is_user_online = this.onlineUsers.find(online_user_id => online_user_id === user_id);
      return !!is_user_online
    },
    isCurrentUserOnline() {
      const is_user_online = this.onlineUsers.find(online_user_id => online_user_id === this.currentUser._id);
      return !!is_user_online
    },
    waitForSomeMilliseconds(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms)
      })
    },
    getWaitTimeExp(retryCount) {
      if (retryCount === 0) {
        return 0;
      }

      // eslint-disable-next-line no-restricted-properties
      const waitTime = (Math.pow(2, retryCount) * 100);
      return waitTime;
    },
    initializeSocketIO() {
      //* initialize Socket.io
      // if (this.$socket.disconnected) {
      //   const socket_token = this.useJwt().authService.getAccessToken();
      //   this.$socket.client.io.opts.query = { token: socket_token };
      //   this.$socket.client.open();
      //   console.log("[SOCKET CONNECTED]")
      // } else {
      //   console.log("[SOCKET ALREADY CONNECTED]")
      // }
    },
    async retrySocketConnectionWithExponentialBackoff(depth = 0) {
      if (this.$socket.client.disconnected) {
        if (depth > 10) {
          // if its still disconnected, then something is wrong.
          // throw an error
          throw new Error("There was an issue communicating to the server. Please try refreshing the page.")
        }

        this.initializeSocketIO();

        const waitTimeInMs = this.getWaitTimeExp(depth);
        await this.waitForSomeMilliseconds(waitTimeInMs);
        return this.retrySocketConnectionWithExponentialBackoff(depth + 1);
      }
      return true;
    },
    async logout() {
      try {
        await this.useJwt().authService.logout();
      } finally {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to login page
        const redirect_route_name = 'app-root';

        sessionStorage.setItem("last_page_accessed", this.$route.path);

        this.$router.replace({ name: redirect_route_name })
          .then(() => {
            this.$store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
          })
      }
    },
  },
}

export const _ = null
