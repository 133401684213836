export default [
  {
    path: '/client/home',
    name: 'client-home',
    component: () => import('@/pages/client/home'),
    beforeEnter: (to, _, next) => {
      const last_page_accessed = sessionStorage.getItem('last_page_accessed');
      if (
        last_page_accessed &&
        last_page_accessed !== to.name &&
        !last_page_accessed.includes("auth")
      ) {
        sessionStorage.removeItem("last_page_accessed");
        return next({ path: last_page_accessed });
      }
      return next();
    },
    meta: {
      pageTitle: 'Activities & Events',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Activities & Events',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Dashboard",
      roles: ["client"]
    },
  },
  {
    path: '/client/events/:id',
    name: 'client-single-event',
    component: () => import('@/pages/client/events/view'),
    meta: {
      pageTitle: 'Event Details',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Activities & Events',
          to: { name: 'client-home' },
        },
        {
          text: 'Event Details',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "View Event Details",
      roles: ["client"]
    },
  },
  {
    path: '/client/events/:id/reserve',
    name: 'client-reserve-event',
    component: () => import('@/pages/client/reservations/new'),
    meta: {
      pageTitle: 'Reserve Spot',
      contentClass: 'ecommerce-application',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Activities & Events',
          to: { name: 'client-home' },
        },
        {
          text: 'Reserve Spot',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Event Spot",
      roles: ["client"]
    },
  },
  {
    path: '/client/reservations',
    name: 'client-reservations',
    component: () => import('@/pages/client/reservations/list'),
    meta: {
      pageTitle: 'Reservations',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Activities & Events',
          to: { name: 'client-home' },
        },
        {
          text: 'Reservations',
          active: true,
        }
      ],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Reservations",
      roles: ["client"]
    },
  },
  {
    path: '/client/reservations/:reservation_id',
    name: 'reservation-details',
    component: () => import('@/pages/client/reservations/view'),
    meta: {
      pageTitle: 'Reservations',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Activities & Events',
          to: { name: 'client-home' },
        },
        {
          text: 'Reservations',
          to: { name: 'client-reservations' },
        },
        {
          text: 'Reservation Details',
          active: true,
        }
      ],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client Reservation Details",
      roles: ["client"]
    },
  },
  {
    path: '/client/blog/:slug',
    name: 'client-single-blog',
    component: () => import('@/pages/client/blogs/view'),
    meta: {
      pageTitle: 'Blogs',
      action: "read",
      resource: "ClientViewBlogPostRoute",
      breadcrumb: [
        {
          text: 'Activities & Events',
          to: { name: 'client-home' },
        },
        {
          text: 'List',
          to: { name: 'client-blogs' },
        },
        {
          text: 'Details',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "View Blog Details",
      roles: ["client"]
    },
  },
  {
    path: '/client/blogs',
    name: 'client-blogs',
    component: () => import('@/pages/client/blogs/list'),
    meta: {
      pageTitle: 'Blogs',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Blogs',
          active: true,
        }
      ],
      roles: ["client"],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Blogs",
      contentClass: '',
    },
  },
]
