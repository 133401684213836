export default class ClientJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchEvent(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/events/${id}`, {})
  }

  makeReservation(event_id, payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientEndpoint}/reservations/${event_id}`, payload)
  }

  fetchReservation(id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/reservations/${id}`, {})
  }

  printReservationDetails(id) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientEndpoint}/reservations/${id}/generate-pdf`, {})
  }

  fetchReservations(params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/reservations`, {
      params
    })
  }

  fetchReservationPayments(reservation_id, params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientEndpoint}/reservations/${reservation_id}/payments`, { params })
  }

  makeReservationPayment(reservation_id, payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientEndpoint}/reservations/${reservation_id}/payments`, payload)
  }

  makeMerchandisePayment(reservation_id) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientEndpoint}/reservations/${reservation_id}/merchandise-payment`, {})
  }
}

